<template>
  <div>
  <div class="row">
    <div class="col col-xs mb-12">
      <h1>{{ getGame.name }}</h1>
    </div>
  </div>
  <div class="row m-0">
    <div class="row col-xxl-6">
      <div class="col-lg-5 bg-light-primary px-6 py-8 rounded-xl mr-7 mb-7">
      <span class="svg-icon svg-icon-3x svg-icon-primary d-block my-2">
              <inline-svg src="/media/svg/icons/Communication/Group.svg" />
            </span>
        <a href="#" class="text-primary font-size-h6">
          <span class="font-weight-bold display-4">{{ getGame.userCount }}</span>
          Joueur<span v-if="getGame.userCount > 1">s</span><br />
          <span class="font-weight-bold display-4">{{
              getDayMember
            }}</span>
          Nouveau<span v-if="getDayMember > 1">x</span> Aujourd'hui
        </a>
      </div>
      <div class="col-lg-5 bg-light-primary px-6 py-8 rounded-xl mr-7 mb-7">
      <span class="svg-icon svg-icon-3x svg-icon-primary d-block my-2">
              <inline-svg src="/media/svg/icons/Communication/Group.svg" />
            </span>
        <a href="#" class="text-primary font-size-h6">
          <span class="font-weight-bold display-4">{{ sponsorCount }}</span>
          Parrainage<span v-if="sponsorCount > 1">s</span><br />
          <span class="font-weight-bold display-4">{{
              sponsorTodayCount
            }}</span>
          <span v-if="sponsorTodayCount > 1">x</span> Aujourd'hui
        </a>
      </div>
      <div class="col-lg-5 bg-light-success px-6 py-8 rounded-xl mr-7 mb-7">
      <span class="svg-icon svg-icon-3x svg-icon-success d-block my-2">
             <inline-svg src="/media/svg/icons/Communication/Share.svg" />
            </span>
        <a href="#" class="text-success font-size-h6">
          <span class="font-weight-bold display-4">{{totalShareCount }}</span>
          Partage<span v-if="totalShareCount > 1">s</span><br />
        </a>
      </div>
    </div>
    <div class="col-xxl-6">
      <div class="col-md-12">
        <div class="card card-custom gutter-b example example-compact">
          <div class="card-header">
            <h3 class="card-title">Classement du jeu</h3>
          </div>
          <div class="card-body">
            <GameLeaderboard :leaderboard="leaderboard" />
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import GameLeaderboard from "@/view/pages/Games/GameLeaderboard";
export default {
  name: "Game",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    GameLeaderboard
  },
  data (){
    return {
      totalShareCount: 0,
      sponsorCount: 0,
      sponsorTodayCount: 0,
      leaderboard: []
    };
  },
  computed: {
    ...mapGetters(["gamesInfo", "playzoneInfo", "playersInfo"]),
    getGame: function() {
      console.log(this.gamesInfo);
      let game;
      if (this.gamesInfo.length > 0) {
        game = this.gamesInfo.find(singleGame => {
          return (
            singleGame.game_index.toLowerCase() === this.$route.params.index
          );
        });
        let status = "Terminé";
        if (
          this.$moment(game.ends_at).isAfter() &&
          this.$moment(game.starts_at).isBefore()
        ) {
          status = "En cours";
        } else if (this.$moment(game.starts_at).isAfter()) {
          status = "À venir";
        }
        game.status = status;
        game.userCount = game.users.length;
        game.startsAt = this.$moment(game.starts_at).format("DD/MM/YY");
        game.endsAt = this.$moment(game.ends_at).format("DD/MM/YY");
      }

      return game;
    },
    getDayMember() {
      let count = 0;

      if (this.playersInfo.length && this.playersInfo.length > 0) {
        this.playersInfo.forEach(player => {
          if (this.$moment().isSame(player.created_at, "day") && (player.playzone_id === this.playersInfo.id)) {
            count++;
          }
        });
      }
      return count;
    },
  },
  created() {
    document.title =
      this.getGame.name + " | " + this.playzoneInfo.playzone_name;
    ApiService.post("game-details", { gameId: this.getGame.id })
        .then(({ data }) => {
          console.log(data);
          this.totalShareCount = data.shareTotalCount;
          this.sponsorCount = data.sponsorCount;
          this.sponsorTodayCount = data.sponsorTodayCount;
          this.leaderboard = data.leaderboard
      })
        .catch(err => console.log(err));
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Tous les jeux",
        route: "/" + this.playzoneInfo.slug + "/games"
      },
      { title: this.getGame.name }
    ]);
    console.log(this.getGame);
  }
};
</script>
