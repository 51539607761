<template>
  <div>
    <div class="d-flex justify-center align-items-center">
      <b-form-select
          class="col-3 col-xs-6 numPerPage"
          v-model="perPage"
          :options="pageOptions"
      ></b-form-select>
      <div class="description found col-6 col-xs-6">
        <strong>Total: {{ totalRows }}</strong>
      </div>
    </div>
    <b-table
        striped
        hover
        :items="getItems"
        :fields="fields"
        id="my-table"
        show-empty
        :per-page="perPage"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
    ></b-table>
    <b-pagination
        :total-rows="totalRows"
        v-model="currentPage"
        :per-page="perPage"
        aria-controls="my-table"
    ></b-pagination>
  </div>
</template>

<script>
export default {
  name: "GameLeaderboard",
  props: {
    leaderboard: {
      Array,
      required: true
    }
  },
  data() {
    return {
      fields: [],
      perPage: 10,
      currentPage: 1,
      sortBy: "lastname",
      sortDesc: false,
      /* Number.MAX_SAFE_INTEGER = 9007199254740991 */
      pageOptions: [
        5,
        10,
        20,
        50,
        {
          value: Number.MAX_SAFE_INTEGER,
          text: "tout afficher"
        }
      ]
    };
  },
  computed: {
    getItems() {
      const items = [];

      if (this.leaderboard.length > 0) {
        this.leaderboard.forEach(user => {
          let showUser = {};
          if (user) {
            showUser = {
              rang: user[3],
              firstname: user[1],
              score: user[2]
            };
            items.push(showUser);
          }
        });
      }
      return items;
    },
    totalRows() {
      return this.leaderboard.length;
    }
  },
  mounted() {
    this.fields = [
      {
        key: "rang",
        sortable: true,
        label: "rang"
      },
      {
        key: "firstname",
        sortable: true,
        label: "Nom"
      },
      {
        key: "score",
        sortable: true,
        label: "Score"
      }
    ];
  }
}
</script>

<style scoped>

</style>